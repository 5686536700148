<template>
  <v-card-text
    id="report"
    v-if="association && Object.keys(association).length > 0"
  >
    <div id="report_pdf">
      <Report_header type="All Collections" />
      <Top_association :association="association" />
      <Report_case :association="association" :payment-amount="paymentAmount" />
    </div>
    <Report_data_table :payments="association.payments.slice(0, 1)" />
  </v-card-text>
  <Report_destructor v-else />
</template>

<script>
import { mapGetters } from "vuex";
import { GetOnlyOpened, processMoney } from "@/functions";
export default {
  name: "last_note",
  components: {
    Report_destructor: () => import("@/components/report/report_destructor"),
    Report_data_table: () => import("@/components/report/report_data_table"),
    Report_case: () => import("@/components/report/report_case"),
    Top_association: () => import("@/components/report/top_association"),
    Report_header: () => import("@/components/report/report_header")
  },
  computed: {
    ...mapGetters({
      // associations: "customAssociations",
      // payments: "customPayments",
      report: "report"
    }),
    association() {
      return GetOnlyOpened(this.reports)[this.report.index];
    },
    paymentAmount() {
      return Math.round(
        this.association.payments.reduce(
          (a, b) => a + processMoney(b["Payment Amount"]),
          0
        )
      );
    }
    // payment() {
    //   return new Array(
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     ...this.association.payments.sort((a, b) => {
    //       let ad = new Date(a["Payment_Date"] || a["Payment Date"]),
    //         bd = new Date(b["Payment_Date"] || b["Payment Date"]);
    //       if (ad < bd) {
    //         return 1;
    //       } else if (ad > bd) {
    //         return -1;
    //       } else {
    //         return 0;
    //       }
    //     })
    //   ).slice(0, 1);
    // }
    // reportPayments() {
    //   let payments = GetOnlyOpened(this.AllPayments);
    //   payments = getByAssAndOwner(this.association, payments);
    //   payments.map((r, index, arr) => {
    //     arr[index] = {
    //       Payment_Date: r["Payment Date"],
    //       staff: r["staff"],
    //       memo: r["Memo"],
    //       "Payment Amount": r["Payment Amount"]
    //     };
    //     this.$emit("collected", payments);
    //     return true;
    //   });
    //   payments = payments.sort((a, b) => {
    //     let ad = new Date(a["Payment_Date"]),
    //       bd = new Date(b["Payment_Date"]);
    //     if (ad < bd) {
    //       return 1;
    //     } else if (ad > bd) {
    //       return -1;
    //     } else {
    //       return 0;
    //     }
    //   });
    //
    //   return payments;
    // },
    // paymentAmount() {
    //   return Math.round(
    //     this.reportPayments.reduce(
    //       (a, b) => a + processMoney(b["Payment Amount"]),
    //       0
    //     )
    //   );
    // }
  },
  props: {
    reports: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped></style>
